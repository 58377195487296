<!-- 发送联系方式组件 -->
<template>
  <el-dialog :title="`联络申请`" :visible.sync="loginShow" :append-to-body="true" @closed="closeClick"
    :close-on-click-modal="false" >
    <div class="maskLayer"></div>
    <template v-if="hintInfo === 2">
      <div class="hintInfo">
        <div class="hintImg">
          <div>
            <img src="@/assets/image/approve/chengg.png" alt=""/>
          </div>
        </div>
        <p class="title">已成功向用户A发送您的联系方式</p>
        <p class="title" v-if="formData.phone">手机号：{{formData.phone}}</p>
        <p class="title" v-if="formData.wechat">微信：{{formData.wechat}}</p>
        <p class="title" v-if="formData.mailbox">邮箱：{{formData.mailbox}}</p>
        <p class="title" v-if="formData.telephone">固定电话：{{formData.telephone}}</p>
        <p class="title" v-if="formData.qq">QQ：{{formData.qq}}</p>
        <p class="return"><span>{{timing}}s</span>后关闭页面</p>
        <el-button type="primary" plain @click="closeClick">返回</el-button>
      </div>
    </template>
    <template v-else>
      <div class="login-div">
        <div class="label">用户"{{formInfo.userName}}"向您发起联络申请</div>
        <el-input type="textarea" disabled :rows="2" placeholder="用户A输入的内容" v-model="formInfo.relationContent" resize="none" maxlength="240"
          show-word-limit>
        </el-input>
        <el-image 
          v-if="formInfo.relationFile"
          class="infoImg"
          :src="formInfo.relationFile" 
          :preview-src-list="[formInfo.relationFile]">
        </el-image>
        <div class="label">联系方式</div>
        <ul class="listFour editUl">
          <li class="list">
            <p>手机（选填）</p>
            <el-input v-model="formData.phone" placeholder="请输入手机号"></el-input>
          </li>
          <li class="list">
            <p>微信（选填）</p>
            <el-input v-model="formData.wechat" placeholder="请输入微信号"></el-input>
          </li>
          <li class="list">
            <p>邮箱（选填）</p>
            <el-input v-model="formData.mailbox" placeholder="请输入邮箱号"></el-input>
          </li>
          <li class="list">
            <p>固定电话（选填）</p>
            <el-input v-model="formData.telephone" placeholder="请输入固定电话"></el-input>
          </li>
          <li class="list">
            <p>QQ（选填）</p>
            <el-input v-model="formData.qq" placeholder="请输入QQ号"></el-input>
          </li>
        </ul>
      </div>
    </template>
    <span slot="footer" class="dialog-footer">
      <template v-if="hintInfo === 1">
        <el-button @click="noClone">取 消</el-button>
        <el-button type="primary" @click="submit(0)" style=" background: #0075B4; color:#fff ">确 定</el-button>
      </template>
    </span>
  </el-dialog>
</template>

<script>
export default {
  // 组件
  components: {},
  data () {
    return {
      formInfo:this.$store.state.sendContactShow.data, // 表单信息
      loginShow:true,
      timing: 0, // 倒计时
      timer: null, // 定时器
      hintInfo: 1, // 提示信息打开 1 表单，2 成功，3失败
      problemDescription: '', // 输入内容
      formData:{}, // 表单数据
    };
  },
  props: {
  },
  computed: {
  },
  mounted () {
    this.formData = {
      id: this.formInfo.id, //  'id',
      isOpen: null, //是否公开联系方式 0公开 1不公开
      phone:this.formInfo.phone, //  '手机号',
      wechat:this.formInfo.wechat, //  '微信号',
      mailbox: this.formInfo.mailbox, // '电子邮箱',
      telephone:this.formInfo.telephone, //  '固定电话',
      qq:this.formInfo.qq, //  'qq号'
    }
  },
  methods: {
    // 点击取消
    noClone(){
      this.$confirm('是否取消向用户A发送您的联系方式?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.closeClick();
      }).catch(() => {
              
      });
    },
    // 关闭弹窗
    closeClick(){
      if(this.hintInfo!=2){
        this.submit(1)
      }
      this.$store.commit('setSendContactShow',{data:null,show: false})
    },
    // 定时器
    timingMet(){
      this.timer=setTimeout(()=>{
        this.timing--;
        if(this.timing<=0){
          this.closeClick()
        }else{
          this.timingMet()
        }
      },1000)
    },
    submit (type) { // 提交 type  0公开 1不公开
      this.formData.isOpen= type //是否公开联系方式 0公开 1不公开
      this.$Api.memberMemberRelationUpdateRelation(this.formData).then(res => {
        if (res.code == 0) {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
          this.hintInfo=2;
          clearTimeout(this.timer)
          this.timing=5;
          this.timingMet();
        }
      })
    }
  },
};
</script>
<style lang="less" scoped>
.infoImg{
  margin-left: 35px;
}
.editUl{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 30px;
    .widthLi{
      width:100% !important;
    }
    .list{
      margin: 0 5px;
      padding-bottom: 16px;
      p{
        color: #666666;
        line-height: 22px;
        position: relative;
        padding-bottom: 8px;
        height: 22px;
      }
      .el-input,.el-select{
        width: 100%;
        height: 40px;
      }
      .butSelect{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        div{
          width: 100%;
          margin: 0 8px;
          height: 40px;
          background: rgba(255,255,255,0.2);
          border-radius: 4px;
          border: 1px solid #DCE1E6;
          text-align: center;
          line-height: 40px;
          cursor: pointer;
          &:first-child{
            margin: 0 8px 0 0;
          }
          &:last-child{
            margin: 0 0 0 8px;
          }
        }
        .selectedBut{
          background: rgba(0,117,180,0.06);
          border: 1px solid #0075B4;
          color: #0075B4;
        }
      }
    }
  }
.hintInfo{
  position: relative;
  z-index:10;
  .hintImg{
    width: 80px;
    height: 80px;
    background: rgba(81, 182, 116, 0.12);
    margin: 60px auto 40px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    border-radius: 50%;
    div{
      border-radius: 50%;
      width: 60px;
      height: 60px;
      background: #51B674;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width:32px;
        height: 32px;
      }
    }
  }
  .title{
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    line-height: 24px;
    text-align: center;
  }
  .return{
    padding: 8px 0  32px 0;
    font-size: 12px;
    color: rgba(102, 102, 102, 1);
    text-align: center;
    span{
      color: rgba(0, 117, 180, 1);
    }
  }
  .el-button{
    display: block;
    margin: 0 auto;
    width: 124px;
    height: 40px;
  }
}
/deep/.el-dialog {
  width:960px;
  border-radius: 10px;
  background-color: #f7f7fc;

  .el-dialog__body {
    padding: 0
  }

  .el-dialog__header {
    // display: none;
    padding: 24px 32px 0px 32px;
  }
  .el-dialog__body{
    .maskLayer{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient( 26deg, rgba(249,249,249,0) 0%, rgba(248,249,249,0.02) 50%, #1ACDFF 100%);
      opacity: 0.16;
    }
  }
  .el-dialog__footer,.popUpDialog,.el-dialog__header{
    position: relative;
    z-index: 10;
  }

  .login-div {
    min-height: 410px;
    border-radius: 8px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    z-index:10;

    .el-icon-circle-close {
      cursor: pointer;
      position: absolute;
      right: -32px;
      color: #999;
      font-size: 30px;
    }

    .title-name {
      
      font-weight: 500;
      font-size: 20px;
      color: #333333;
      text-align: left;
      font-style: normal;
      margin: 24px 0 0 32px;
    }

    .label {
      padding-bottom: 20px;
      font-weight: 500;
      font-size: 16px;
      color: #333333;
      text-align: left;
      font-style: normal;
      margin: 24px 0 0 32px;
    }

    .el-textarea {
      width: 896px;
      height: 120px;
      background: #FFFFFF;
      border-radius: 8px;
      margin: 0 0 0 32px;

      .el-textarea__inner {
        height: 100%;
      }
    }

    .upload {
      width: 896px;
      height: 120px;
      border-radius: 8px;
      margin: 24px 0 0 32px;
      display: flex;
    }
  }
}
</style>
