import {$get, $post} from "@/utils/http";
let portApi = {
    // 登录
    login (params) {
    return new Promise((resolve, reject) => {
          $post("/member/relation/login", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 退出登录
  logout (params) {
    return new Promise((resolve, reject) => {
          $post("/member/relation/logout", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 获取验证码
  aliyunSendCode (params) {
    return new Promise((resolve, reject) => {
          $post("/member/relation/aliyunSendCode", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 修改头像
  editPicture (params) {
    return new Promise((resolve, reject) => {
          $post(`/member/relation/editPicture?fileName=${params.fileName}`, {}).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 支付宝支付 /member/pay/alipay
  memberPayAlipay (params) {
    return new Promise((resolve, reject) => {
          $post("/member/pay/alipay", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 获取用户信息
  getUserInfo (params) {
   return new Promise((resolve, reject) => {
          $get("/member/relation/loginUserAuthInfo", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  
  // 已经付费列表
  memberOrderCurrent (params) {
    return new Promise((resolve, reject) => {
          $post("/member/order/current", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 评价列表
  memberEvaluateQuery (params) {
    return new Promise((resolve, reject) => {
          $post("/member/evaluate/query", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  
  // 对商品进行评价
  memberEvaluateAdd (params) {
    return new Promise((resolve, reject) => {
          $post("/member/evaluate/add", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  
  // 获取联系方式
  
  /****** 晴天 *******/
  // 会员行业下拉
  industryList (params) {
   return new Promise((resolve, reject) => {
          $get("/member/industry/list", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 会员职业下拉
  occupationList (params) {
   return new Promise((resolve, reject) => {
          $get("/member/occupation/list", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 会员接口判断
  memberBasicsEstimateMember (params) {
    return new Promise((resolve, reject) => {
          $post("/member/memberBasics/estimateMember", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },

  // 会员基础信息查询列表
  memberBasicsList (params) {
    return new Promise((resolve, reject) => {
          $post("/member/memberBasics/list", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 添加会员工作经历
  experienceAdd (params) {
    return new Promise((resolve, reject) => {
          $post("/member/experience/add", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 添加会员获奖情况
  honorAdd (params) {
    return new Promise((resolve, reject) => {
          $post("/member/honor/add", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  
  // 添加会员执业证书
  practisingAdd (params) {
    return new Promise((resolve, reject) => {
          $post("/member/practising/add", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 添加会员项目经历
  projectAdd (params) {
    return new Promise((resolve, reject) => {
          $post("/member/project/add", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 添加会员律师诉讼案例
  lawyerAdd (params) {
    return new Promise((resolve, reject) => {
          $post("/member/lawyer/add", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 会员详情信息查询
  memberBasicsParticulars (params) {
    return new Promise((resolve, reject) => {
          $post("/member/memberBasics/particulars", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
 
  // 添加收藏
  memberCollectibleAdd (params) {
    return new Promise((resolve, reject) => {
          $post("/member/collectible/add", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 取消收藏
  memberCollectibleCancel (params) {
    return new Promise((resolve, reject) => {
          $post("/member/collectible/delete", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 收藏列表
  memberCollectibleList (params) {
    return new Promise((resolve, reject) => {
          $post("/member/collectible/list", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  
  // 查询会员用户收藏夹和pk状态
  memberCollectibleGetstatus (params) {
    return new Promise((resolve, reject) => {
          $post("/member/collectible/getstatus", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
   // 个人已获取会员联系方式会员列表
   memberContactList (params) {
    return new Promise((resolve, reject) => {
          $post("/member/contact/list", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  
  // 获取验证码
  memberWechatLogin (params) {
   return new Promise((resolve, reject) => {
          $get("/member/wechat/login", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  
  // 首页列表
  memberHomepageList (params) {
    return new Promise((resolve, reject) => {
          $post("/member/homepage/list", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
   // 首页排序列表
   memberHomepagePermutation (params) {
    return new Promise((resolve, reject) => {
          $post("/member/homepage/permutation", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 会员角色字典表下拉接口
  memberOccupationList (params) {
   return new Promise((resolve, reject) => {
          $get("/member/occupation/list", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  //获取用户基本信息接口
  loginUserBasicMessage (params) {
   return new Promise((resolve, reject) => {
          $get("/member/relation/loginUserBasicMessage", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  relationBasicMessage (params) {
    return new Promise((resolve, reject) => {
          $post("/member/relation/basicMessage", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  //阿里云会员支付接口
  memberAlipay (params) {
   return new Promise((resolve, reject) => {
          $get(`/member/memberPay/alipay`,params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  //微信支付接口
  weChatAlipay (params) {
    return new Promise((resolve, reject) => {
           $post(`/member/memberPay/chatPay/qrcode`,params).then((res) => {
               if(res && (res.result=="000000" || res.code == 0)){
                   resolve(res)
               }else{
                   reject(res)
               }
           });
       })
   },
  //支付宝根据订单编号查询支付结果
  memberPayQuery (params) {
   return new Promise((resolve, reject) => {
          $get(`/member/memberPay/query?traceNo=${params.traceNo}`).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  //微信根据订单编号查询支付结果
  memberPayWechatQuery (params) {
    return new Promise((resolve, reject) => {
           $get(`/member/memberPay/wechat/query/${params.traceNo}`).then((res) => {
               if(res && (res.result=="000000" || res.code == 0)){
                   resolve(res)
               }else{
                   reject(res)
               }
           });
       })
   },
  //设置提现账户
  memberAccountExtract (params) {
    return new Promise((resolve, reject) => {
           $post(`/member/account/extract`,params).then((res) => {
               if(res && (res.result=="000000" || res.code == 0)){
                   resolve(res)
               }else{
                   reject(res)
               }
           });
       })
   },
   //修改提现账户
  memberAccountExtractUpdate (params) {
    return new Promise((resolve, reject) => {
           $post(`/member/account/extract/update`,params).then((res) => {
               if(res && (res.result=="000000" || res.code == 0)){
                   resolve(res)
               }else{
                   reject(res)
               }
           });
       })
   },
   //获取提现账户
  memberAccountGetExtract (params) {
    return new Promise((resolve, reject) => {
           $get(`/member/account/getExtract`,params).then((res) => {
               if(res && (res.result=="000000" || res.code == 0)){
                   resolve(res)
               }else{
                   reject(res)
               }
           });
       })
   },
   //提现
  memberAccountWithdrawal (params) {
    return new Promise((resolve, reject) => {
           $post(`/member/account/withdrawal`,params).then((res) => {
               if(res && (res.result=="000000" || res.code == 0)){
                   resolve(res)
               }else{
                   reject(res)
               }
           });
       })
   },
   //账户收入支出提现记录
  memberAccountIncomeExpend (params) {
    return new Promise((resolve, reject) => {
           $post(`/member/account/incomeExpend`,params).then((res) => {
               if(res && (res.result=="000000" || res.code == 0)){
                   resolve(res)
               }else{
                   reject(res)
               }
           });
       })
   },
  //图片上传
  minioUpload (params) {
    return new Promise((resolve, reject) => {
          $post("/member/file/minio/upload", params, "", {
        contentType: "multipart/form-data",
      }).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  //首页----问题反馈
  problemFeedback (params) {
    return new Promise((resolve, reject) => {
          $post("/member/mutual/homepage/problemFeedback", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },

    //首页----个人详情接口
    personPreview (params) {
    return new Promise((resolve, reject) => {
          $get("/member/person/preview", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  //首页----会员详情接口
  personDetails (params) {
    return new Promise((resolve, reject) => {
          $post("/member/person/details", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
//   对比信息
  //添加会员对比信息
  memberCompareAdd (params) {
    return new Promise((resolve, reject) => {
          $post("/member/compare/add", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  //查询会员对比信息列表
  memberCompareList (params) {
    return new Promise((resolve, reject) => {
          $get("/member/compare/list", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  //删除会员对比信息
  memberCompareDeleter (params) {
    return new Promise((resolve, reject) => {
          $post("/member/compare/delete", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  //清空会员对比信息列表
  memberCompareDel (params) {
    return new Promise((resolve, reject) => {
          $get("/member/compare/del", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  //多会员信息对比列表
  memberCompareContrast (params) {
    return new Promise((resolve, reject) => {
          $post("/member/compare/contrast", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
//判断是否已获取会员手机号
memberMemberBasicsGainphone (params) {
    return new Promise((resolve, reject) => {
          $post("/member/memberBasics/gainphone", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  //添加获取联系方式会员信息
  memberContactAdd (params) {
    return new Promise((resolve, reject) => {
          $post("/member/contact/add", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  //个人待评价条数和总数
  memberContactAppraiserow (params) {
    return new Promise((resolve, reject) => {
          $get("/member/contact/appraiserow", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  //添加会员评价
  memberTechnologyAdd (params) {
    return new Promise((resolve, reject) => {
          $post("/member/technology/add", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },




  //其他会员对我的评价列表
  memberTechnologyList (params) {
    return new Promise((resolve, reject) => {
          $post("/member/technology/list", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  //查看某个会员对我的评价信息
  memberTechnologyQureycomment (params) {
    return new Promise((resolve, reject) => {
          $post("/member/technology/qureycomment", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
   //查看个人对某个会员已评价详情
   memberTechnologyQureycreatorcommentt (params) {
    return new Promise((resolve, reject) => {
          $post("/member/technology/qureycreatorcomment", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  //回复致谢感言
  memberTechnologyWriteback (params) {
    return new Promise((resolve, reject) => {
          $post("/member/technology/writeback", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  //其他会员对我的评价未回复条数和总数
  memberTechnologyWritekrow (params) {
    return new Promise((resolve, reject) => {
          $get("/member/technology/writekrow", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  //待追评条数和初评总数
  memberTechnologyFollowrow (params) {
    return new Promise((resolve, reject) => {
          $get("/member/technology/followrow", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  //个人待追加评论列表
  memberTechnologyFollowlist (params) {
    return new Promise((resolve, reject) => {
          $post("/member/contact/followlist", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  //追评查看个人初评回显信息
  memberTechnologyFollowcomment(params) {
    return new Promise((resolve, reject) => {
          $post("/member/technology/followcomment", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  //添加追评信息
  memberTechnologyAddfollow (params) {
    return new Promise((resolve, reject) => {
          $post("/member/technology/addfollow", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },


    //   首页弹出联络申请
    memberMemberRelationHomepage (params) {
        return new Promise((resolve, reject) => {
            $get("/member/memberRelation/homepage", params).then((res) => {
                if(res && (res.result=="000000" || res.code == 0)){
                    resolve(res)
                }else{
                    reject(res)
                }
            });
        })
    },
    // 联系对方
    memberMemberRelationAdd (params) {
        return new Promise((resolve, reject) => {
            $post("/member/memberRelation/add", params).then((res) => {
                if(res && (res.result=="000000" || res.code == 0)){
                    resolve(res)
                }else{
                    reject(res)
                }
            });
        })
    },
    // 我的联络信息
    memberMemberRelationMessageList (params) {
        return new Promise((resolve, reject) => {
            $post("/member/memberRelation/messageList", params).then((res) => {
                if(res && (res.result=="000000" || res.code == 0)){
                    resolve(res)
                }else{
                    reject(res)
                }
            });
        })
    },
    // 是否发送联系方式
    memberMemberRelationUpdateRelation (params) {
        return new Promise((resolve, reject) => {
            $post("/member/memberRelation/updateRelation", params).then((res) => {
                if(res && (res.result=="000000" || res.code == 0)){
                    resolve(res)
                }else{
                    reject(res)
                }
            });
        })
    },
  // 接收确认消息
  memberMemberRelationAffirm (params) {
    return new Promise((resolve, reject) => {
        $get(`/member/memberRelation/affirm/${params.id}`, {}).then((res) => {
            if(res && (res.result=="000000" || res.code == 0)){
                resolve(res)
            }else{
                reject(res)
            }
        });
    })
},
}

export default portApi