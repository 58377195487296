<template>
  <!-- 
    element-loading-spinner="el-icon-loading" -->
  <div id="app"
    element-loading-text="加载中"
    v-loading.fullscreen.lock="$store.state.loading"
    element-loading-background="rgba(0, 0, 0, 0.2)"
  >
    <router-view></router-view>
    <!-- 用户发送联络请求组件 -->
    <template v-if="$store.state.sendContactShow.show">
      <sendContact ></sendContact>
    </template>
    <!-- 接收消息组件 -->
    <template v-if="$store.state.receiveInfoShow.show">
      <receiveInfo ></receiveInfo>
    </template>
  </div>
</template>

<script>
import sendContact from '@/components/sendContact/sendContact.vue'; // 发送联系组件
import receiveInfo from '@/components/receiveInfo/receiveInfo.vue'; // 接收联系组件
export default {
  name: 'App',
  components: {sendContact,receiveInfo},
  data () {
    return {
      timer: null // 定时器
    };
  },
  mounted(){
    clearInterval(this.timer);
    this.getMemberMemberRelationHomepage();
  },
  methods:{
    // 获取弹出联络申请
    getMemberMemberRelationHomepage(){
      this.timer=setInterval(()=>{
        console.log('触发')
        if(this.$usage.getToken()){
          if(!this.$store.state.sendContactShow.show && !this.$store.state.receiveInfoShow.show ){
            this.$Api.memberMemberRelationHomepage().then(res=>{
              if(res.data){
                if(res.data.queryType === 1){ // 被联系
                  this.$store.commit('setSendContactShow', {data:res.data,show:true})
                }else if(res.data.queryType === 2){ // 主动联系
                  this.$store.commit('setReceiveInfoShow', {data:res.data,show:true})
                }
              }
            })
          }
        }
      },10000)
    }
  }
}
</script>

<style>

</style>
