<!-- 接收联系方式组件 -->
<template>
  <el-dialog :visible.sync="getTouchShow" :append-to-body="true" :show-close="false" :close-on-click-modal="false">
    <div class="getTouch">
      <!-- 支付成功 -->
      <div class="paymentSuccessDiv">
        <div class="sucImg">
          <div>
            <img src="@/assets/image/approve/chengg.png" alt=""/>
          </div>
        </div>
        <div v-show="stopNum === 1">
          <p class="sucText">用户"{{formInfo.byUserName}}"同意您的联络申请</p>
          <p class="sucInfo">您可以通过以下方式与TA取得联系</p>
          <p class="sucInfo" v-if="formInfo.phone">手机号：{{formInfo.phone}}</p>
          <p class="sucInfo" v-if="formInfo.wechat">微信：{{formInfo.wechat}}</p>
          <p class="sucInfo" v-if="formInfo.mailbox">邮箱：{{formInfo.mailbox}}</p>
          <p class="sucInfo" v-if="formInfo.telephone">固定电话：{{formInfo.telephone}}</p>
          <p class="sucInfo" v-if="formInfo.qq">QQ：{{formInfo.qq}}</p>
          <p class="sucInfo">如若联系不到对方，请及时通过“问题反馈”联系平台</p>
          <el-button type="primary" @click="closeClick(1)">确定</el-button>
        </div>
        <div v-show="stopNum === 2">
          <p class="sucText">信息已归档至“我的信息”</p>
          <p class="sucInfo">相关记录已永久保存并随时调阅</p>
          <el-button type="primary" @click="closeClick(2)">确定</el-button>
        </div>
      </div>
    </div>

    <span slot="footer" class="dialog-footer"></span>
  </el-dialog>
</template>

<script>
export default {
  // 组件
  components: {},
  data () {
    return {
      formInfo:this.$store.state.receiveInfoShow.data, // 表单信息
      stopNum: 1, // 步骤
      getTouchShow:true, // 接收联系方式弹窗
      thisPhone:'17723123123', // 当前用户手机号
    };
  },
  computed: {
  },
  created(){
  },
  mounted () {
  },
  beforeDestroy () {
  },
  methods: {
    closeClick(type){
      if(type === 1){
        this.$Api.memberMemberRelationAffirm({id:this.formInfo.id}).then(res=>{})
        this.stopNum=2;
      }else if(type === 2){
        this.$store.commit('setReceiveInfoShow',{data:null, show: false})
      }
    }
  },
};
</script>
<style lang="less" scoped>
.paymentSuccessDiv{
  padding: 49px 0 32px 0;
  .sucImg{
    width: 80px;
    height: 80px;
    background: rgba(81, 182, 116, 0.12);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    div{
      width: 60px;
      height: 60px;
      background: #51B674;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: 32px;
        height: 32px;
      }
    }
  }
  .sucText{
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    line-height: 24px;
    padding: 24px 0 8px 0;
    text-align: center;
  }
  .sucInfo{
    font-size: 14px;
    color: #333;
    line-height: 20px;
    padding-bottom:8px;
    text-align: center;
  }
  .el-button{
    display: block;
    margin: 32px auto 0 auto;
    width: 120px;
    height: 40px;
  }
}
:deep(.el-dialog) {
  width:480px;
  border-radius: 8px;

  .el-dialog__body {
    padding: 0
  }

  .el-dialog__header,.el-dialog__footer {
    display: none;
  }

}
</style>
