import Vue from 'vue'
import App from './App.vue'
import store from './store/index'
import router from './router/index'
import { $get, $post } from './utils/http'
import ElementUI from 'element-ui';
import $usage from './utils/usage'
import 'element-ui/lib/theme-chalk/index.css';
import "@/assets/styles/reset.css";
import "@/assets/styles/onlineTest.css";

import portApi from '@/api/api.js'
import ApiUser from '@/api/apiUser.js'

// 改的代码
// import navHeader from "./components/nav-header.vue";
// import Card from "./components/card.vue";
// import Login from "./components/login.vue";
// // 公共组件
// Vue.component("navHeader", navHeader);
// Vue.component("Card", Card);
// Vue.component("Login", Login);


Vue.use(ElementUI)
Vue.prototype.$Api = portApi;
Vue.prototype.$ApiUser = ApiUser;
Vue.prototype.$get = $get;
Vue.prototype.$post = $post;
Vue.prototype.$usage = $usage;
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

$usage.cacheAssignmentVuex()
